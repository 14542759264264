/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

( function ( $ ) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        // menuScroll();
        // ajax_search();

        $(document).on("click", "#cart", function (e) {
          e.preventDefault();
          jQuery( ".shopping-cart" ).fadeToggle( "fast" );
          jQuery( ".shopping-cart-background" ).fadeToggle( "fast" );
        } );

        //Add plus and minus to quantity field
        $('.quantity').on('click', '.plus', function(e) {
            $input = $(this).prev('input.qty');
            var val = parseInt($input.val());
            var step = $input.attr('step');
            step = 'undefined' !== typeof(step) ? parseInt(step) : 1;
            $input.val( val + step ).change();
        });

        $('.quantity').on('click', '.minus',
            function(e) {
            $input = $(this).next('input.qty');
            var val = parseInt($input.val());
            var step = $input.attr('step');
            step = 'undefined' !== typeof(step) ? parseInt(step) : 1;
            if (val > 0) {
                $input.val( val - step ).change();
            }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        //better_wpcf7_forms();
       // $( '.gallery a.thumbnail' ).fluidbox();
        $( '.gallery a.thumbnail img' ).each( function ( i, img ) {
          if ( img.src !== img.getAttribute( 'src' ) ) {
            $( img ).replaceWith( $( img.outerHTML ) );
          }
        } );

        // flyout menu
        $( '.navbar-collapse.width' ).on( 'show.bs.collapse hide.bs.collapse', function ( e ) {
          $( 'body' ).toggleClass( 'menu-visible' );
        } );


        
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function ( func, funcname, args ) {
      var fire;
      var namespace = Sage;
      funcname = ( funcname === undefined ) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[ func ];
      fire = fire && typeof namespace[ func ][ funcname ] === 'function';

      if ( fire ) {
        namespace[ func ][ funcname ]( args );
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire( 'common' );

      // Fire page-specific init JS, and then finalize JS
      $.each( document.body.className.replace( /-/g, '_' ).split( /\s+/ ), function ( i, classnm ) {
        UTIL.fire( classnm );
        UTIL.fire( classnm, 'finalize' );
      } );

      // Fire common finalize JS
      UTIL.fire( 'common', 'finalize' );
    }
  };

  // Load Events
  $( document ).ready( UTIL.loadEvents );

} )( jQuery ); // Fully reference jQuery after this point.
